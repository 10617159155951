import React, { useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInventoryContext, ItemForm, ItemsTable } from "domain/Inventory";
import { FiltersGroup } from "domain/Filters";
import { StickersForPrint } from "domain/Print";
import { ScrollPosition, SideBar, useToggler, useModalContext, Button, Spinner } from "shared";
import { GoPlus } from "react-icons/go";
import { HiOutlinePrinter } from "react-icons/hi";
import { ModalConfirmation } from "shared/components/Modal/components/modal-confirmation/modal-confirmation";
import { NavButton } from "shared/components/nav-button/nav-button";
import "./home.page.scss";
import { SettingsIcon } from "assets";
import { useLocalStorage } from "domain/App/hooks";
import { PreferencesForm } from "domain/Inventory";
import { initialDisplayedColumns, DISPLAYED_COLUMNS } from "shared/constants/constants";

export const HomePage = () => {
  const { items, addItem, selectedItems, isLoading } = useInventoryContext();
  const { createModal } = useModalContext();
  const [isCreateBtnLoading, setIsCreateBtnLoading] = useState(false);
  const [createInventoryErrorMessage, setCreateInventoryErrorMessage] = useState("");
  const { isOpen, onOpenHandler, onCloseHandler } = useToggler();
  const navigate = useNavigate();

  const [displayedColumns, setDisplayedColumns] = useLocalStorage(DISPLAYED_COLUMNS, initialDisplayedColumns);

  const { element: CreateNewItemModal, open, close } = useMemo(() => createModal(), [createModal]);
  const {
    element: CloseNewItemModalConfirmation,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useMemo(() => createModal(), [createModal]);

  const onCreateNewItem = useCallback(
    (newItem, count) => {
      setIsCreateBtnLoading(true);
      addItem(newItem, count)
        .then((data) => {
          close();
          data && navigate(`/item/${data[0].itemId}`);
        })
        .catch((error) => setCreateInventoryErrorMessage(error.response.data))
        .finally(() => setIsCreateBtnLoading(false));
    },
    [addItem, close, navigate]
  );

  const onSubmitConfirmationModal = () => {
    closeConfirmationModal();
    close();
  };

  const onCloseConfirmationModal = () => {
    closeConfirmationModal();
  };

  const {
    element: ChangePreferencesModal,
    open: openPreferencesModal,
    close: closePreferencesModal,
  } = useMemo(() => createModal(), [createModal]);

  const onOpenPreferencesModal = useCallback(() => {
    openPreferencesModal();
  }, [openPreferencesModal]);

  const savePreferences = (values) => {
    setDisplayedColumns(values);
    closePreferencesModal();
  };

  const onClosePreferencesModal = () => {
    closePreferencesModal();
  };

  const onOpenStickerSideBar = () => {
    onOpenHandler();
  };

  return (
    <>
      <div className="home-header">
        <NavButton />
        <div className="home-header_left">
          <FiltersGroup />
        </div>
        <div className="home-header_right">
          <div className="home-header_right_primary_cta">
            <Button
              buttonPrefixIcon={<HiOutlinePrinter className="btn__print" />}
              buttonText={"Štampaj nalepnice"}
              buttonColor={"blue"}
              event={onOpenStickerSideBar}
              isDisabled={!selectedItems.length}
            />
            <Button
              buttonText={"Novi predmet"}
              buttonIcon={<GoPlus className="btn__plus-icon" />}
              buttonColor={"blue"}
              event={(e) => {
                setIsCreateBtnLoading(false);
                setCreateInventoryErrorMessage("");
                open();
              }}
            />
            <Button buttonIcon={<SettingsIcon className="preference__icon" />} event={onOpenPreferencesModal} />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="items-table__loading">
          <Spinner size="large" />
        </div>
      ) : items ? (
        <ScrollPosition uniqueId="dashboard-page">
          <ItemsTable displayedColumns={displayedColumns} data={items} isReadOnly={false} />
        </ScrollPosition>
      ) : null}
      <ChangePreferencesModal>
        <PreferencesForm
          displayedColumns={displayedColumns}
          onCancel={onClosePreferencesModal}
          onSubmit={savePreferences}
          title="Preference"
        />
      </ChangePreferencesModal>
      <CreateNewItemModal>
        <ItemForm
          onSubmit={onCreateNewItem}
          onCancel={openConfirmationModal}
          isBtnLoading={isCreateBtnLoading}
          errorMessage={createInventoryErrorMessage}
        />
      </CreateNewItemModal>
      <CloseNewItemModalConfirmation>
        <ModalConfirmation
          onSubmit={onSubmitConfirmationModal}
          onCancel={onCloseConfirmationModal}
          text={<p>Da li ste sigurni da želite da zatvorite prozor?</p>}
        />
      </CloseNewItemModalConfirmation>
      <SideBar onClose={onCloseHandler} isOpen={isOpen} sideBarToggle floatRight>
        <StickersForPrint isOpen={isOpen} onClose={onCloseHandler} selectedItems={selectedItems} />
      </SideBar>
    </>
  );
};
