import React, { useEffect, useMemo, useState } from "react";

import { useLocationsContext } from "domain/Location";
import { useCategoriesContext } from "domain/Category";
import { useCompaniesContext } from "domain/Company";
import { useEmployeesContext } from "domain/Employee";
import { Button, Dropdown, InputSwitch, SearchBar } from "shared";
import { BsFilter } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

import { useItemsFilters } from "../../hooks";

import "./filters-group.scss";
import { useFiltersContext } from "../../context";
import { SortingGroup, useSortingContext } from "domain/Sorting";
import clsx from "clsx";
import { common, sortingOptions } from "shared/constants/constants";
import { useInvoicesContext } from "domain/Invoice";

export const FiltersGroup = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [clearChoices, setClearChoices] = useState(false);
  const { applyPendingFilters, clearFilters } = useFiltersContext();
  const { applySortingHandler, clearSorting } = useSortingContext();
  const { handleCategoriesSearch, categoriesFilter } = useCategoriesContext();
  const { companies } = useCompaniesContext();
  const { locations, handleLocationSearch } = useLocationsContext();
  const { employees, handleEmployeeSearch } = useEmployeesContext();
  const { invoices, handleInvoicesSearch } = useInvoicesContext();
  const {
    onSearchQueryChange,
    onSmallInventoryValueFilterChange,
    onCategoriesFilterChange,
    onCompanyFilterChange,
    onLocationFilterChange,
    onEmployeeFilterChange,
    onInvoicesFilterChange,
    onAssignedFilterChange,
    onOwnershipFilterChange,
  } = useItemsFilters();

  const isSmallInventoryOptions = useMemo(
    () => [
      { name: common.SITAN_INVENTAR, value: true },
      { name: common.OSNOVNO_SREDSTVO, value: false },
    ],
    []
  );

  const toggleFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  const closeFiltersHandler = () => {
    setShowFilters(false);
  };

  const clearFiltersHandler = () => {
    clearFilters();
    clearSorting();
    setClearChoices(true);
  };

  const updateClearHandler = () => {
    setClearChoices(false);
  };

  const applyFiltersHandler = () => {
    applyPendingFilters();
    applySortingHandler();
    closeFiltersHandler();
  };

  useEffect(() => {
    if (showFilters) return;
    applyPendingFilters();
  }, [showFilters, applyPendingFilters]);

  return (
    <div className="filters">
      <div className="filters__search">
        <SearchBar
          onChange={onSearchQueryChange}
          placeholder="Pretraži"
          autoComplete="off"
          spellCheck="false"
          id="searchQuery"
        ></SearchBar>
        <button
          className={clsx("filters__btn", { "filters__btn--active": showFilters })}
          onClick={toggleFiltersHandler}
        >
          <BsFilter />
        </button>
      </div>
      <div className={clsx("filters__container", { "filters__container--active": showFilters })}>
        <div className="filters__wrap">
          <div className="filters__close">
            <Button
              buttonIcon={<IoClose className="filters__close-icon" />}
              buttonColor={"red"}
              event={closeFiltersHandler}
            />
          </div>
          <div className="filters__options">
            <div className="filters__field">
              <p className="filters__name">Tip</p>
              <Dropdown
                choices={isSmallInventoryOptions}
                onChange={(smallInventories) => {
                  updateClearHandler();
                  return onSmallInventoryValueFilterChange(smallInventories);
                }}
                isMultipleChoice
                allSelectedLabel="Sve"
                clearChoices={clearChoices}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Kategorije</p>
              <Dropdown
                choices={categoriesFilter}
                onChange={(categories) => {
                  updateClearHandler();
                  return onCategoriesFilterChange(categories);
                }}
                searchId="searchCategory"
                onSearchChange={handleCategoriesSearch}
                isMultipleChoice
                allSelectedLabel="Sve kategorije"
                clearChoices={clearChoices}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Korisnik</p>
              <Dropdown
                choices={companies}
                onChange={(companies) => {
                  updateClearHandler();
                  return onCompanyFilterChange(companies);
                }}
                allSelectedLabel="Sve kompanije"
                clearChoices={clearChoices}
                isMultipleChoice
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Vlasnik</p>
              <Dropdown
                choices={companies}
                onChange={(companies) => {
                  updateClearHandler();
                  return onOwnershipFilterChange(companies);
                }}
                allSelectedLabel="Sve kompanije"
                clearChoices={clearChoices}
                isMultipleChoice
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Lokacije</p>
              <Dropdown
                choices={locations}
                onChange={(locations) => {
                  updateClearHandler();
                  return onLocationFilterChange(locations);
                }}
                isMultipleChoice
                allSelectedLabel="Sve lokacije"
                searchId="searchLocation"
                onSearchChange={handleLocationSearch}
                clearChoices={clearChoices}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Kolege</p>
              <Dropdown
                choices={employees}
                onChange={(employees) => {
                  updateClearHandler();
                  return onEmployeeFilterChange(employees);
                }}
                isMultipleChoice
                allSelectedLabel="Sve kolege"
                searchId="searchEmployees"
                onSearchChange={handleEmployeeSearch}
                clearChoices={clearChoices}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Fakture</p>
              <Dropdown
                choices={invoices}
                onChange={(invoices) => {
                  updateClearHandler();
                  return onInvoicesFilterChange(invoices);
                }}
                isMultipleChoice
                allSelectedLabel="Sve fakture"
                searchId="searchInvoices"
                onSearchChange={handleInvoicesSearch}
                clearChoices={clearChoices}
              />
            </div>
            <div className="filters__sort-wrap">
              <SortingGroup
                clearChoices={clearChoices}
                onSelection={updateClearHandler}
                sortingOptions={sortingOptions}
              />
            </div>
          </div>
          <div className="filters__bottom">
            <div className="filters__field">
              <p className="filters__name">Prikaži neraspoređena sredstva</p>
              <InputSwitch name="assignToFilter" onChange={onAssignedFilterChange}></InputSwitch>
            </div>
            <div className="filters__ctas">
              <Button buttonText={"Potvrdi"} buttonColor={"blue"} event={applyFiltersHandler} />
              <Button buttonText={"Poništi"} buttonColor={"red"} event={clearFiltersHandler} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
