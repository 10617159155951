import React from "react";

import "./configuration-changes-section.scss";

import { FaArrowRight } from "react-icons/fa";

import { ItemSmallInventoryLabel } from "domain/Inventory";

import { common } from "shared/constants/constants";

export const ConfigurationChangesSection = ({ data }) => {
  return (
    <div>
      {data.map((change) => {
        return (
          <div className="configuration-changes__state_section" key={change.description}>
            <p>{change.description}</p>
            <div className="configuration-changes__changed_state">
              {change.stateBefore === common.SITAN_INVENTAR ? (
                <ItemSmallInventoryLabel isChecked />
              ) : (
                change.stateBefore
              )}
              <FaArrowRight className="btn__arrow-icon" />{" "}
              {change.stateAfter === common.OSNOVNO_SREDSTVO ? <ItemSmallInventoryLabel /> : change.stateAfter}
            </div>
            <hr className="line" />
          </div>
        );
      })}
    </div>
  );
};
