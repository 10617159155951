import React, { useEffect, useState } from "react";

import { useCompaniesContext } from "domain/Company";
import { Button, Dropdown, TextInput } from "shared";
import { BsFilterLeft } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

import { useItemsExportFilters } from "../../hooks";
import { useExportContext } from "../../context";
import clsx from "clsx";
import { common } from "shared/constants/constants";

import "./export-filters-group.scss";
import { useCategoriesContext } from "domain/Category";

const formatOptions = [
  { name: "CSV", value: "csv" },
  { name: "XLSX", value: "xlsx" },
];
const formatDefaultOption = [{ name: "XLSX", value: "xlsx" }];

const isSmallInventoryOptions = [
  { name: common.SITAN_INVENTAR, value: true },
  { name: common.OSNOVNO_SREDSTVO, value: false },
];

export const ExportFiltersGroup = () => {
  const [clearChoices, setClearChoices] = useState(false);
  const [showExportFilters, setShowExportFilters] = useState(false);
  const [partsExcluded, setPartsExcluded] = useState(false);

  const { companies } = useCompaniesContext();
  const { categories } = useCategoriesContext();
  const { applyPendingExportFilters, pendingExportFilters, clearFilters } = useExportContext();
  const {
    onSmallInventoryValueFilterChange,
    onCompanyFilterChange,
    onFormatFilterChange,
    onCategoryValueFilterChange,
    onOwnershipFilterChange,
    onCheckboxFilterChange,
  } = useItemsExportFilters();

  useEffect(() => {
    onFormatFilterChange(formatDefaultOption);
  }, [onFormatFilterChange]);

  const toggleExportFiltersHandler = () => {
    setShowExportFilters(!showExportFilters);
  };

  const closeExportFiltersHandler = () => {
    setShowExportFilters(false);
  };

  const applyExportFiltersHandler = () => {
    applyPendingExportFilters();
    closeExportFiltersHandler();
  };

  const clearFiltersHandler = () => {
    clearFilters();
    setClearChoices(true);
  };

  const updateClearHandler = () => {
    setClearChoices(false);
  };

  const isApplyDisabled = () => {
    if (!pendingExportFilters["format"]) {
      return true;
    }
    return false;
  };

  const onPartsExcludedChange = (e) => {
    const value = e.target.checked;
    const filter = { name: "partsExcluded", value };
    setPartsExcluded(value);
    onCheckboxFilterChange(filter);
    onCategoryValueFilterChange([]);
  };

  return (
    <div className="filters">
      <div className="filters__search">
        <p className="export-description">Molimo Vas da odaberete filtere da biste videli podatke za export.</p>
        <button
          className={clsx("filters__btn", { "filters__btn--active": showExportFilters })}
          onClick={toggleExportFiltersHandler}
        >
          <BsFilterLeft />
        </button>
      </div>
      <div className={clsx("filters__container", { "filters__container--active": showExportFilters })}>
        <div className="filters__wrap">
          <div className="filters__close">
            <Button
              buttonIcon={<IoClose className="filters__close-icon" />}
              buttonColor={"red"}
              event={closeExportFiltersHandler}
            />
          </div>
          <div className="filters__options">
            <div className="filters__field">
              <TextInput
                type="checkbox"
                onChange={onPartsExcludedChange}
                label="Radne stanice zbirno"
                value={partsExcluded}
              />
              <p className="filters__name">Kategorije</p>
              <Dropdown
                disabled={partsExcluded}
                choices={categories}
                onChange={onCategoryValueFilterChange}
                isMultipleChoice
                allSelectedLabel="Izaberi kategoriju"
                clearChoices={clearChoices || partsExcluded}
                onSelection={updateClearHandler}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Tip</p>
              <Dropdown
                choices={isSmallInventoryOptions}
                onChange={onSmallInventoryValueFilterChange}
                isMultipleChoice
                allSelectedLabel="Izaberi tip"
                clearChoices={clearChoices}
                onSelection={updateClearHandler}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Korisnik</p>
              <Dropdown
                choices={companies}
                onChange={onCompanyFilterChange}
                allSelectedLabel="Izaberi korisnika"
                clearChoices={clearChoices}
                onSelection={updateClearHandler}
                isMultipleChoice
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Vlasnik</p>
              <Dropdown
                choices={companies}
                onChange={onOwnershipFilterChange}
                allSelectedLabel="Izaberi vlasnika"
                clearChoices={clearChoices}
                onSelection={updateClearHandler}
                isMultipleChoice
              />
            </div>
            <div className="filters__field">
              <label>
                Format<span className="text-input__required">*</span>
              </label>
              <Dropdown
                choices={formatOptions}
                onChange={onFormatFilterChange}
                isMultipleChoice={false}
                allSelectedLabel="Izaberi format"
                clearChoices={clearChoices}
                onSelection={updateClearHandler}
                defaultValue={formatDefaultOption}
              />
            </div>
          </div>
          <div className="filters__bottom">
            <div className="filters__ctas">
              <Button
                buttonText={"Potvrdi"}
                buttonColor={"blue"}
                event={applyExportFiltersHandler}
                isDisabled={isApplyDisabled()}
              />
              <Button buttonText={"Poništi"} buttonColor={"red"} event={clearFiltersHandler} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
