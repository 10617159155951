import { useMemo } from "react";

const itemLabels = {
  navigatorId: "Navigator id",
  itemId: "Evidencioni broj",
  isSmallInventory: "Tip",
  "category.name": "Kategorija",
  label: "Naziv",
  assignedTo: "Dodeljeno",
  "location.office": "Kancelarija",
  "company.name": "Korisnik",
  "company.owner": "Vlasnik",
  activationDate: "Datum aktivacije",
  "invoice.number": "Broj fakture",
  "invoice.purchaseDate": "Datum fakture",
  supplier: "Dobavljač",
};

export function useItemTableLabels(displayedColumns) {
  const columns = useMemo(() => {
    const visibleColumns = Object.keys(itemLabels).reduce((itemLabel, key) => {
      itemLabel["button"] = "";

      if (displayedColumns[key]) {
        itemLabel[key] = itemLabels[key];
      }
      return itemLabel;
    }, {});

    return Object.keys(visibleColumns).map((key) => {
      return {
        Header: visibleColumns[key],
        accessor: key,
      };
    });
  }, [displayedColumns]);

  return { columns };
}
