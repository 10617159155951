import clsx from "clsx";
import moment from "moment";
import { useMemo } from "react";
import { common } from "shared/constants/constants";
import "./history-extra-info-item.scss";

const SmallInventoryLabel = ({ positive }) => {
  return (
    <span className={clsx("item-small-inventory-label ", { positive })}>
      {positive ? common.SITAN_INVENTAR : common.OSNOVNO_SREDSTVO}
    </span>
  );
};

export const HistoryExtraInfoItem = ({
  label = "",
  extraBefore,
  extraAfter,
  isSmallInventory = false,
  isDate = false,
}) => {
  const isBeforeAvailable = extraBefore || (typeof extraBefore === "string" && extraBefore.length > 0);
  const isAfterAvailable =
    typeof extraAfter !== "undefined" || (typeof extraAfter === "string" && extraAfter.length > 0);

  const extraAfterValue = useMemo(() => {
    if (!isAfterAvailable || (!isSmallInventory && !extraAfter) || !label) return null;

    if (isSmallInventory) {
      return <SmallInventoryLabel positive={extraAfter} />;
    } else if (isDate && moment(new Date(extraAfter)).isValid()) {
      return moment(new Date(extraAfter)).format(common.PREFERRED_FE_DATE_FORMAT);
    } else {
      return extraAfter;
    }
  }, [extraAfter, isAfterAvailable, isDate, isSmallInventory, label]);

  if (!isAfterAvailable || (!isSmallInventory && !extraAfter) || !label) return null;

  const beforeSmallInventory = isSmallInventory ? (
    <SmallInventoryLabel positive={extraBefore || !extraAfter} />
  ) : (
    extraBefore
  );

  return (
    <div className="history-item__extra-item">
      {isBeforeAvailable && <div className="history-item__extra-item-label">{label}</div>}
      <div className="history-item__extra-item-values">
        <div
          className={clsx("history-item__extra-item-before", {
            "history-item__extra-item-before--with-label": !isBeforeAvailable,
          })}
        >
          {isBeforeAvailable ? beforeSmallInventory : label}
        </div>
        <div className="history-item__extra-item-separator">
          <span className="history-item__chevron history-item__chevron--right"></span>
        </div>
        <div className="history-item__extra-item-after">{extraAfterValue}</div>
      </div>
    </div>
  );
};
